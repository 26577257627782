import React from 'react'
import { css } from "@emotion/react"
import styled from '@emotion/styled'
import Button from '~components/Button'
import Section from '~components/Section'
import { mobile, tablet } from "~styles/global"

const LoadMore = ({ className, onClick }) => {
  return(
    <Wrap className={className}>
      <Container>
        <Line/>
        <LoadButton borderButton onClick={onClick}>Load More</LoadButton>
        <Line/>
      </Container>
    </Wrap>
  )
}

const Wrap = styled(Section)`
  margin-bottom: 118px;
  ${mobile}{
    margin-bottom: 45px;
  }
`
const Container = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
`
const LoadButton = styled(Button)`
  margin: 0 22px;
	${tablet}{
    padding: 1px 5px;
	}
  ${mobile}{
    padding: 2px 10px 4px;
    margin: 0 10px;
  }
`
const Line = styled.div`
  background: var(--black);
  height: 1px;
`
export default LoadMore
